'use strict';

angular.module('enervexSalesappApp').factory('AccountTerritoryRequest', function ($resource) {
	return $resource('/api/accounts/:accountId/account-locations/:accountLocationId/account-territory-requests/:id/:approve/:deny', {
		id: '@_id',
		approve: '@approve',
		deny: '@deny'
	},
	{
		update: {
			method: 'PUT'
		},
		approve: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				accountLocationId: '@accountLocationId',
				id: '@accountTerritoryRequestId',
				approve: 'approve'
			}
		},
		deny: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				accountLocationId: '@accountLocationId',
				id: '@accountTerritoryRequestId',
				deny: 'deny'
			}
		}
	});
});
